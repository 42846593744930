//AssessmentNew.vue

<template>
	<div class="container">
		<h1 class="-text-primary">
			<i class="material-icons">assignment</i>Assessment New
		</h1>

		<p class="lead -blue-grey-text text-lighten-2">
			Fill out the form below to be faxed to the patient's facility staff.
		</p>

		<form class="pt-5" v-on:submit.prevent="submitForm(false)">
			<section class="card">
				<section class="card-body">
					<h5 class="card-title">
						<i class="material-icons">attachment</i>Meta Data
					</h5>

					<pre>form.meta: {{ form.meta }}</pre>
					<pre>therapist: {{ form.therapist }}</pre>

					<div class="form-row">
						<div class="col-sm mb-3">
							<label for="validationCustom01">Respiratory Therapist Name</label>
							<input
								v-model="form.therapist.name"
								disabled
								type="text"
								class="form-control -form-control-lg"
								id="date"
								name="date"
								placeholder="Respiratory Therapist Name"
							/>
						</div>

						<!-- <div class="col-sm mb-3">
							<label for="validationCustom01">State License Number</label>
							<input v-model="form.therapist.license" disabled type="text" class="form-control -form-control-lg" id="date" name="date" placeholder="State License Number"  >
						</div>-->

						<div class="col-sm mb-3">
							<label for="validationCustom01">Credentials</label>
							<input
								v-model="form.therapist.credentials"
								disabled
								type="text"
								class="form-control -form-control-lg"
								id="therapistCreditials"
								name="therapistCreditials"
								placeholder
							/>
						</div>
					</div>

					<div class="form-row">
						<div class="col-sm mb-3">
							<label for="validationCustom01">Date</label>
							<input
								v-model="form.meta.date"
								disabled
								type="text"
								class="form-control -form-control-lg"
								id="date"
								name="date"
								placeholder
							/>
						</div>

						<div class="col-sm mb-3">
							<label for="validationCustom01">Start Time</label>
							<input
								v-model="form.meta.start"
								disabled
								type="text"
								class="form-control -form-control-lg"
								id="start_time"
								name="start_time"
								placeholder
							/>
						</div>

						<div class="col-sm mb-3">
							<label for="validationCustom01">End Time</label>
							<input
								v-model="form.meta.end"
								disabled
								type="text"
								class="form-control -form-control-lg"
								id="end_time"
								name="end_time"
								placeholder
							/>
						</div>
					</div>
				</section>
			</section>

			<div class="form-group mt-5">
				<h2 class="pt-5">
					<i class="material-icons">location_city</i>Facility
				</h2>

				<p class="text-muted">
					This form will be faxed to the facility or hospital below.
				</p>

				<select
					:disabled="disableFacility"
					class="form-control form-control-lg custom-select"
					name="hospital"
					id="hospital"
					v-model="form.meta.facility"
					v-on:change="facilitySelected"
				>
					<option value>- Select Facility -</option>

					<template v-for="hos in facilities">
						<option :value="hos.id" :key="hos.id">{{ hos.name }}</option>
					</template>
				</select>
			</div>

			<div class="form-group mt-5">
				<h2 class="pt-5">
					<i class="material-icons">bookmark</i>Purpose of Interaction
				</h2>

				<p class="text-muted">
					This form will be faxed to the facility or hospital below.
				</p>

				<pre>purpose: {{ form.purpose }}</pre>

				<div class="row mt-3">
					<div
						v-for="p in callPurposes"
						class="col-6 col-sm-4"
						:key="'purpose-' + p.id"
					>
						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.purpose"
								type="checkbox"
								:id="'purpose-' + p.id"
								class="custom-control-input"
								:value="p.id"
							/>
							<label :for="'purpose-' + p.id" class="custom-control-label">{{
								p.name
							}}</label>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group mt-5">
				<h2 class="pt-5"><i class="material-icons">person</i>Patient Data</h2>

				<p class="text-muted">
					This form will be faxed to the facility or hospital below.
				</p>

				<pre>patient: {{ form.patient }}</pre>

				<div class="form-row">
					<div class="col-sm-6 mb-3">
						<label for="patient_name_first">Patient First Name</label>
						<input
							v-model="form.patient.name.first"
							type="text"
							class="form-control form-control-lg"
							id="patient_name_first"
							name="patient_name_first"
							placeholder="Patient First Name"
							required
						/>
						<div class="valid-feedback">Looks good!</div>
					</div>

					<div class="col-sm-6 mb-3">
						<label for="patient_name_last">Patient Last Name</label>
						<input
							v-model="form.patient.name.last"
							type="text"
							class="form-control form-control-lg"
							id="patient_name_last"
							name="patient_name_last"
							placeholder="Patient Last Name"
							required
						/>
						<div class="valid-feedback">Looks good!</div>
					</div>

					<div class="col-md-5 mb-3">
						<label for="patient_identifier">Patient Identifier</label>
						<input
							v-model="form.patient.identifier"
							type="text"
							class="form-control form-control-lg"
							id="patient_identifier"
							name="patient_identifier"
							placeholder="Patient Identifier"
						/>
						<div class="valid-feedback">Looks good!</div>
					</div>

					<div class="col-md-7 mb-3">
						<label>Patient Birthday</label>
						<div class="input-group">
							<select
								v-model="form.patient.birthday.mm"
								class="form-control form-control-lg custom-select"
								id="patient_birth_month"
								name="patient_birth_month"
								required
							>
								<option value>- Month -</option>

								<option
									:value="m.value"
									:key="m.value"
									v-for="m in monthsOptions"
									>{{ m.name }}</option
								>
							</select>
							<select
								v-model="form.patient.birthday.dd"
								class="form-control form-control-lg custom-select"
								id="patient_birth_day"
								name="patient_birth_day"
								required
							>
								<option value>- Day -</option>
								<option v-for="n in 31" :key="n">{{ n }}</option>
							</select>
							<input
								v-model="form.patient.birthday.yy"
								type="number"
								class="form-control form-control-lg"
								id="patient_birth_year"
								name="patient_birth_year"
								placeholder="Year"
								maxlength="4"
								required
							/>
						</div>
					</div>

					<div class="col-md-4 mb-3">
						<label for="validationCustom01">Gender</label>
						<select
							v-model="form.patient.gender"
							class="form-control form-control-lg custom-select"
							id="patient_gender"
							name="patient_gender"
							required
						>
							<option value>- Select Gender -</option>
							<option>Male</option>
							<option>Female</option>
							<option>Other</option>
						</select>
						<div class="valid-feedback">Looks good!</div>
					</div>
				</div>
			</div>

			<div class="form-group mt-5">
				<h2 class="pt-5">
					<i class="material-icons">content_paste</i>Subjective
				</h2>

				<p class="text-muted">
					This form will be faxed to the facility or hospital below.
				</p>

				<pre>subjective: {{ form.subjective }}</pre>

				<div class="row">
					<div class="col-md-6 mb-3">
						<label for="validationCustom01">Facility Admission Dx</label>
						<textarea
							v-model="form.subjective.hospitalAdmissionDx"
							class="form-control form-control-lg"
							id="hospitalAdmissionDx"
							rows="8"
						></textarea>
					</div>

					<div class="col-md-6 mb-3">
						<label for="validationCustom01">Provider Obtained Info</label>
						<textarea
							v-model="form.subjective.providerObtainedInfo"
							class="form-control form-control-lg"
							id="providerObtainedInfo"
							rows="8"
						></textarea>
					</div>
				</div>
			</div>

			<div class="form-group mt-5">
				<h2 class="pt-5">
					<i class="material-icons">history</i>Respiratory History
				</h2>

				<p class="text-muted">
					This form will be faxed to the facility or hospital below.
				</p>

				<pre>respiratoryHistory: {{ form.respiratoryHistory }}</pre>

				<div class="row">
					<div class="col-md-6 mt-3">
						<label for="respiratoryDiagnosis">Respiratory Diagnosis</label>
						<textarea
							v-model="form.respiratoryHistory.respiratoryDiagnosis"
							class="form-control form-control-lg"
							id="respiratoryDiagnosis"
							rows="8"
						></textarea>
					</div>

					<div class="col-md-6 mt-3">
						<label for="currentRespiratoryTherapyTreatments"
							>Current Respiratory Therapy Treatments</label
						>
						<textarea
							v-model="
								form.respiratoryHistory.currentRespiratoryTherapyTreatments
							"
							class="form-control form-control-lg"
							id="currentRespiratoryTherapyTreatments"
							rows="8"
						></textarea>
					</div>
				</div>

				<div class="row">
					<div class="col-md-6 mt-3">
						<label for="respiratoryMedications">Respiratory Medications</label>
						<textarea
							v-model="form.respiratoryHistory.respiratoryMedications"
							class="form-control form-control-lg"
							id="respiratoryMedications"
							rows="8"
						></textarea>
					</div>

					<div class="col-md-6 mt-3">
						<label for="otherMedications">Other Medications</label>
						<textarea
							v-model="form.respiratoryHistory.otherMedications"
							class="form-control form-control-lg"
							id="otherMedications"
							rows="8"
						></textarea>
					</div>
				</div>
			</div>

			<div class="form-group mt-5">
				<h2 class="pt-5">
					<i class="material-icons">assessment</i>Symptom Assessment Data
				</h2>

				<p class="text-muted">
					This form will be faxed to the facility or hospital below.
				</p>

				<pre>respiratorySymptoms: {{ form.respiratorySymptoms }}</pre>

				<div class="row mt-3">
					<div class="col-sm">
						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomNone"
								class="custom-control-input"
								value="None"
							/>
							<label for="symptomNone" class="custom-control-label">None</label>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomHypoxemia"
								class="custom-control-input"
								value="Hypoxemia"
							/>
							<label for="symptomHypoxemia" class="custom-control-label"
								>Hypoxemia</label
							>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomCough"
								class="custom-control-input"
								value="Cough"
							/>
							<label for="symptomCough" class="custom-control-label"
								>Cough</label
							>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomChestCongestion"
								class="custom-control-input"
								value="Chest Congestion"
							/>
							<label for="symptomChestCongestion" class="custom-control-label"
								>Chest Congestion</label
							>
						</div>

						<!-- <div class="custom-control custom-checkbox form-control-lg">
							<input v-model="form.respiratorySymptoms.symptoms" type="checkbox" id="symptomHemoptysis" class="custom-control-input" value="Hemoptysis">
							<label for="symptomHemoptysis" class="custom-control-label">Hemoptysis</label>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input v-model="form.respiratorySymptoms.symptoms" type="checkbox" id="symptomDyspnea" class="custom-control-input" value="Dyspnea">
							<label for="symptomDyspnea" class="custom-control-label">Dyspnea</label>
						</div>-->

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomShortnessAtRest"
								class="custom-control-input"
								value="Shortness of Breath at Rest"
							/>
							<label for="symptomShortnessAtRest" class="custom-control-label"
								>Shortness of Breath at Rest</label
							>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomShortnessOnExertion"
								class="custom-control-input"
								value="Shortness of Breath on Exertion"
							/>
							<label
								for="symptomShortnessOnExertion"
								class="custom-control-label"
								>Shortness of Breath on Exertion</label
							>
						</div>
					</div>

					<div class="col-sm">
						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomHypopnea"
								class="custom-control-input"
								value="Hypopnea"
							/>
							<label for="symptomHypopnea" class="custom-control-label"
								>Hypopnea</label
							>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomRespiratoryFailure"
								class="custom-control-input"
								value="Respiratory Failure"
							/>
							<label
								for="symptomRespiratoryFailure"
								class="custom-control-label"
								>Respiratory Failure</label
							>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomTachycardia"
								class="custom-control-input"
								value="Tachycardia"
							/>
							<label for="symptomTachycardia" class="custom-control-label"
								>Tachycardia</label
							>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomChestPain"
								class="custom-control-input"
								value="Chest Pain"
							/>
							<label for="symptomChestPain" class="custom-control-label"
								>Chest Pain</label
							>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.symptoms"
								type="checkbox"
								id="symptomChangeInMentalStatus"
								class="custom-control-input"
								value="Change in Mental Status"
							/>
							<label
								for="symptomChangeInMentalStatus"
								class="custom-control-label"
								>Change in Mental Status</label
							>
						</div>

						<div class="custom-control custom-checkbox form-control-lg">
							<input
								v-model="form.respiratorySymptoms.otherSymptoms.active"
								type="checkbox"
								id="symptomOtherSymptoms"
								class="custom-control-input"
								value="true"
							/>
							<label for="symptomOtherSymptoms" class="custom-control-label"
								>Other Symptoms</label
							>
						</div>
					</div>
				</div>

				<div
					class="mt-3"
					v-show="form.respiratorySymptoms.otherSymptoms.active"
				>
					<input
						v-model="form.respiratorySymptoms.otherSymptoms.value"
						type="text"
						class="form-control form-control-lg"
						id="symptomOtherSymptomsValue"
						placeholder="Other Symptoms"
					/>
				</div>

				<div class="row mt-3">
					<div class="col-sm mt-3">
						<label for="symptomSpO2">SpO2</label>
						<input
							v-model="form.respiratorySymptoms.spO2"
							type="text"
							class="form-control form-control-lg"
							id="symptomSpO2"
							placeholder="SpO2"
						/>
					</div>

					<div class="col-sm-6 col-md-3 mt-3">
						<label for="symptomRespiratoryRate">Respiratory Rate</label>
						<input
							v-model="form.respiratorySymptoms.respiratoryRate"
							type="text"
							class="form-control form-control-lg"
							id="symptomRespiratoryRate"
							placeholder="Respiratory Rate"
						/>
					</div>

					<div class="col-sm-6 col-md-3 mt-3">
						<label for="symptomHeartRate">Heart Rate</label>
						<input
							v-model="form.respiratorySymptoms.heartRate"
							type="text"
							class="form-control form-control-lg"
							id="symptomHeartRate"
							placeholder="Heart Rate"
						/>
					</div>

					<div class="col-sm-6 col-md-3 mt-3">
						<label for="symptomBloodPressure">Blood Pressure</label>
						<input
							v-model="form.respiratorySymptoms.bloodPressure"
							type="text"
							class="form-control form-control-lg"
							id="symptomBloodPressure"
							placeholder="Blood Pressure"
						/>
					</div>

					<div class="col-sm-6 col-md-3 mt-3">
						<label for="symptomTemperature">Temperature</label>
						<input
							v-model="form.respiratorySymptoms.temperature"
							type="text"
							class="form-control form-control-lg"
							id="symptomTemperature"
							placeholder="Temperature"
						/>
					</div>

					<!-- </div>

					<div class="form-row">-->

					<div class="col-12 col-md mt-3">
						<label for="symptomOtherData">Other</label>
						<input
							v-model="form.respiratorySymptoms.otherData"
							type="text"
							class="form-control form-control-lg"
							id="symptomOtherData"
							placeholder="Other"
						/>
					</div>
				</div>
			</div>

			<div class="form-group mt-5">
				<h2 class="pt-5"><i class="material-icons">opacity</i>Lab Data</h2>

				<p class="text-muted">Numerical text entries.</p>

				<pre>labData: {{ form.labData }}</pre>

				<h3 class="pt-4">ABGs</h3>

				<div class="form-row">
					<!-- <div class="col-sm mt-3">
						<label for="labData.ABGs">ABGs</label>
						<input v-model="form.labData.ABGs" type="text" class="form-control form-control-lg" id="labData.ABGs" name="end_time" placeholder="ABGs"  >
					</div>-->

					<div class="col-sm mt-3">
						<label for="labData.pH">pH</label>
						<input
							v-model="form.labData.pH"
							type="text"
							class="form-control form-control-lg"
							id="labData.pH"
							name="end_time"
							placeholder="pH"
						/>
					</div>

					<div class="col-sm mt-3">
						<label for="labData.CO2">CO2</label>
						<input
							v-model="form.labData.CO2"
							type="text"
							class="form-control form-control-lg"
							id="labData.CO2"
							placeholder="CO2"
						/>
					</div>

					<div class="col-sm mt-3">
						<label for="labData.PO2">PO2</label>
						<input
							v-model="form.labData.PO2"
							type="text"
							class="form-control form-control-lg"
							id="labData.PO2"
							placeholder="PO2"
						/>
					</div>

					<div class="col-sm mt-3">
						<label for="labData.O2">O2</label>
						<input
							v-model="form.labData.O2"
							type="text"
							class="form-control form-control-lg"
							id="labData.O2"
							placeholder="O2"
						/>
					</div>

					<div class="col-sm mt-3">
						<label for="labData.Sat">Sat</label>
						<input
							v-model="form.labData.Sat"
							type="text"
							class="form-control form-control-lg"
							id="labData.Sat"
							placeholder="Sat"
						/>
					</div>

					<div class="col-sm mt-3">
						<label for="labData.HCO3">HCO3-</label>
						<input
							v-model="form.labData.HCO3"
							type="text"
							class="form-control form-control-lg"
							id="labData.HCO3"
							placeholder="HCO3-"
						/>
					</div>

					<div class="col-sm mt-3">
						<label for="validationCustom01">BE</label>
						<input
							v-model="form.labData.BE"
							type="text"
							class="form-control form-control-lg"
							id="labData.BE"
							placeholder="BE"
						/>
					</div>

					<div class="col-sm mt-3" title="EndTidal Carbon Dioxide">
						<label for="labData.EtCO2">EtCO2</label>
						<input
							v-model="form.labData.EtCO2"
							type="text"
							class="form-control form-control-lg"
							id="labData.EtCO2"
							placeholder="EtCO2"
						/>
					</div>
				</div>

				<h3 class="pt-4 mt-4">CBC</h3>

				<div class="row">
					<div class="col-md-4">
						<div class="mt-3">
							<label for="labData.WBC">WBC</label>
							<input
								v-model="form.labData.WBC"
								type="text"
								class="form-control form-control-lg"
								id="labData.WBC"
								placeholder="WBC"
							/>
						</div>
						<div class="mt-3">
							<label for="labData.Hgb">Hgb</label>
							<input
								v-model="form.labData.Hgb"
								type="text"
								class="form-control form-control-lg"
								id="labData.Hgb"
								placeholder="Hgb"
							/>
						</div>
						<div class="mt-3">
							<label for="labData.Na">Na+</label>
							<input
								v-model="form.labData.Na"
								type="text"
								class="form-control form-control-lg"
								id="labData.Na"
								placeholder="Na+"
							/>
						</div>
						<div class="mt-3">
							<label for="labData.K">K+</label>
							<input
								v-model="form.labData.K"
								type="text"
								class="form-control form-control-lg"
								id="labData.K"
								placeholder="K+"
							/>
						</div>
					</div>

					<div class="col-sm mt-3">
						<label for="labData.otherLabData">Other Lab Data</label>
						<textarea
							rows="8"
							v-model="form.labData.otherLabData"
							class="form-control form-control-lg"
							id="labData.otherLabData"
							placeholder="Other"
						></textarea>
					</div>

					<!-- <div class="col-sm mt-3">
						<label for="labData.labTestDescription">Lab Test Description</label>
						<input v-model="form.labData.labTestDescription" type="text" class="form-control form-control-lg" id="labData.labTestDescription" placeholder="Lab Test Description"  >
					</div>-->
				</div>
			</div>

			<div class="form-group mt-5">
				<!-- Breath Sounds -->

				<app-breath-sounds
					:breathSounds="form.breathSounds"
				></app-breath-sounds>

				<div class="mt-5">
					<h2 class="pt-5"><i class="material-icons">bubble_chart</i>Cough</h2>

					<p class="text-muted">
						This form will be faxed to the facility or hospital below.
					</p>

					<pre>cough: {{ form.cough }}</pre>

					<div class="row">
						<div class="col-sm mt-3">
							<label for="cough.strength">Strength</label>
							<select
								v-model="form.cough.strength"
								class="form-control form-control-lg custom-select"
								id="cough.strength"
							>
								<option value>- Strength -</option>
								<option>None</option>
								<option>Strong</option>
								<option>Moderate</option>
								<option>Weak</option>
							</select>
						</div>
						<div class="col-sm mt-3">
							<label for="cough.frequency">Frequency</label>
							<select
								v-model="form.cough.frequency"
								class="form-control form-control-lg custom-select"
								id="cough.frequency"
							>
								<option value>- Frequency -</option>
								<option>On Request</option>
								<option>Spontaneous</option>
								<option>Continuous</option>
							</select>
						</div>
						<div class="col-sm mt-3">
							<label for="cough.sputumAmount">Sputum Amount</label>
							<select
								v-model="form.cough.sputumAmount"
								class="form-control form-control-lg custom-select"
								id="cough.sputumAmount"
							>
								<option value>- Sputum Amount -</option>
								<option>Non-Productive (none)</option>
								<!--   -->
								<option>Swallowed</option>
								<option>Scant</option>
								<option>Small</option>
								<option>Moderate</option>
								<option>Large</option>
								<option>Copious</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-sm mt-3">
							<label for="cough.sputumQuality">Sputum Quality</label>
							<!-- <select v-model="form.cough.sputumQuality" class="form-control form-control-lg" id="cough.sputumQuality" multiple size="10" >
								<option value="">- Sputum Quality -</option>
								<option >None</option>
								<option >Clear</option>
								<option >Yellow	</option>
								<option >Green</option>
								<option >Tan</option>
								<option >Brown</option>
								<option >Red Streaks</option>
								<option >Hemoptysis	</option>
								<option >Pink Frothy</option>
							</select>-->

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.cough.sputumQuality"
									type="checkbox"
									class="custom-control-input"
									id="sputumQualityNone"
									value="None"
								/>
								<label class="custom-control-label" for="sputumQualityNone"
									>None</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.cough.sputumQuality"
									type="checkbox"
									class="custom-control-input"
									id="sputumQualityClear"
									value="Clear"
								/>
								<label class="custom-control-label" for="sputumQualityClear"
									>Clear</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.cough.sputumQuality"
									type="checkbox"
									class="custom-control-input"
									id="sputumQualityYellow"
									value="Yellow"
								/>
								<label class="custom-control-label" for="sputumQualityYellow"
									>Yellow</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.cough.sputumQuality"
									type="checkbox"
									class="custom-control-input"
									id="sputumQualityGreen"
									value="Green"
								/>
								<label class="custom-control-label" for="sputumQualityGreen"
									>Green</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.cough.sputumQuality"
									type="checkbox"
									class="custom-control-input"
									id="sputumQualityTan"
									value="Tan"
								/>
								<label class="custom-control-label" for="sputumQualityTan"
									>Tan</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.cough.sputumQuality"
									type="checkbox"
									class="custom-control-input"
									id="sputumQualityBrown"
									value="Brown"
								/>
								<label class="custom-control-label" for="sputumQualityBrown"
									>Brown</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.cough.sputumQuality"
									type="checkbox"
									class="custom-control-input"
									id="sputumQualityRedStreaks"
									value="Red Streaks"
								/>
								<label
									class="custom-control-label"
									for="sputumQualityRedStreaks"
									>Red Streaks</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.cough.sputumQuality"
									type="checkbox"
									class="custom-control-input"
									id="sputumQualityHemoptysis"
									value="Hemoptysis"
								/>
								<label
									class="custom-control-label"
									for="sputumQualityHemoptysis"
									>Hemoptysis</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.cough.sputumQuality"
									type="checkbox"
									class="custom-control-input"
									id="sputumQualityPinkFrothy"
									value="Pink Frothy"
								/>
								<label
									class="custom-control-label"
									for="sputumQualityPinkFrothy"
									>Pink Frothy</label
								>
							</div>
						</div>

						<div class="col-sm mt-3">
							<label for="cough.notes">Cough Notes</label>
							<textarea
								v-model="form.cough.notes"
								type="text"
								class="form-control form-control-lg"
								id="cough.notes"
								placeholder="Cough Notes"
								rows="8"
							></textarea>
						</div>
					</div>
				</div>

				<div class="mt-5">
					<h2 class="pt-5">
						<i class="material-icons">directions</i>Assessment/Plan
					</h2>

					<p class="text-muted">Respiratory Therapy Recommendations.</p>

					<pre>assessmentPlan: {{ form.assessmentPlan }}</pre>

					<div class="row">
						<div class="col mt-3">
							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.assessmentPlan.selections"
									type="checkbox"
									class="custom-control-input"
									id="assessmentPlanVentilation"
									value="Non-Invasive Positive Pressure Ventilation"
								/>
								<label
									class="custom-control-label"
									for="assessmentPlanVentilation"
									>Non-Invasive Positive Pressure Ventilation</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.assessmentPlan.selections"
									type="checkbox"
									class="custom-control-input"
									id="assessmentPlanBronchialHygiene"
									value="Bronchial Hygiene"
								/>
								<label
									class="custom-control-label"
									for="assessmentPlanBronchialHygiene"
									>Bronchial Hygiene</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.assessmentPlan.selections"
									type="checkbox"
									class="custom-control-input"
									id="assessmentPlanLungExpansion"
									value="Lung Expansion"
								/>
								<label
									class="custom-control-label"
									for="assessmentPlanLungExpansion"
									>Lung Expansion</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.assessmentPlan.selections"
									type="checkbox"
									class="custom-control-input"
									id="assessmentPlanAirwayMedications"
									value="Airway Medications"
								/>
								<label
									class="custom-control-label"
									for="assessmentPlanAirwayMedications"
									>Airway Medications</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.assessmentPlan.selections"
									type="checkbox"
									class="custom-control-input"
									id="assessmentPlanAirwayManagement"
									value="Airway Management"
								/>
								<label
									class="custom-control-label"
									for="assessmentPlanAirwayManagement"
									>Airway Management</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.assessmentPlan.selections"
									type="checkbox"
									class="custom-control-input"
									id="assessmentPlanMechanicalVentilation"
									value="Mechanical Ventilation"
								/>
								<label
									class="custom-control-label"
									for="assessmentPlanMechanicalVentilation"
									>Mechanical Ventilation</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="form.assessmentPlan.selections"
									type="checkbox"
									class="custom-control-input"
									id="assessmentPlanOxygenDelivery"
									value="Oxygen Delivery"
								/>
								<label
									class="custom-control-label"
									for="assessmentPlanOxygenDelivery"
									>Oxygen Delivery</label
								>
							</div>

							<!-- <div class="custom-control custom-checkbox form-control-lg">
								<input v-model="form.assessmentPlan.other.active" type="checkbox" class="custom-control-input" id="assessmentPlanOther">
								<label class="custom-control-label" for="assessmentPlanOther">Other</label>
							</div>-->
						</div>

						<div class="col-sm-12 col-md-7 mt-3">
							<label for="assessmentPlanOtherValue">Assessment Plan</label>
							<textarea
								v-model="form.assessmentPlan.plan"
								class="form-control form-control-lg"
								id="assessmentPlanOtherValue"
								rows="12"
							></textarea>
						</div>
					</div>
				</div>

				<div class="mt-5">
					<h2 class="pt-5">
						<i class="material-icons">bug_report</i>Troubleshooting
					</h2>

					<p class="text-muted">Respiratory Therapy Recommendations.</p>

					<pre>troubleshooting: {{ form.troubleshooting }}</pre>

					<div class="row">
						<div class="col-sm mt-3">
							<label for="troubleshooting.respiratoryEquipment"
								>Respiratory Equipment</label
							>
							<textarea
								v-model="form.troubleshooting.respiratoryEquipment"
								class="form-control form-control-lg"
								id="troubleshooting.respiratoryEquipment"
								rows="8"
							></textarea>
						</div>

						<div class="col-sm mt-3">
							<label for="troubleshooting.patientSituation"
								>Patient Situation</label
							>
							<textarea
								v-model="form.troubleshooting.patientSituation"
								class="form-control form-control-lg"
								id="troubleshooting.patientSituation"
								rows="8"
							></textarea>
						</div>
					</div>
				</div>

				<div class="mt-5">
					<h2 class="pt-5"><i class="material-icons">school</i>Education</h2>

					<p class="text-muted">Respiratory Therapy.</p>

					<pre>eduaction: {{ form.education }}</pre>

					<div class="row">
						<div class="col-sm mt-3">
							<label for="education.patient">Patient</label>
							<textarea
								v-model="form.education.patient"
								class="form-control form-control-lg"
								id="education.patient"
								rows="8"
							></textarea>
						</div>

						<div class="col-sm mt-3">
							<label for="education.staff">Staff</label>
							<textarea
								v-model="form.education.staff"
								class="form-control form-control-lg"
								id="education.staff"
								rows="8"
							></textarea>
						</div>
					</div>
				</div>
			</div>

			<div style="position: relative; height: 60px;">
				<!-- 
					<a data-style="position: fixed; top: 70px; right 0;" :disabled="form.meta.facility == ''"  href class="btn btn-lg btn-primary mt-5" v-on:click.prevent="submitForm(true)">
						<i class="material-icons">&#xE880;</i>Preview Fax
					</a>
				-->

				<button
					style="position: absolute; right: 0;"
					:disabled="form.meta.facility == ''"
					type="submit"
					class="btn btn-lg btn-success mt-5"
				>
					<i class="material-icons">&#xE5C8;</i>Send Fax
				</button>
			</div>
		</form>

		<div class="mt-5 pt-1">
			<iframe width="100%" height="12400" id="previewHtml"
				>Preview will appear here.</iframe
			>
		</div>

		<!-- 
			<div v-if="showIframe" class="floating_iframe mt-3">

				<iframe :src="iframeSource" width="100%" height="1200"></iframe>

			</div>
		--></div>
</template>

<script>
import moment from "moment";

import { mapGetters, mapActions } from "vuex";

import BreathSounds from "./BreathSounds.vue";
//import NoiseRub from './NoiseRub.vue'

export default {
	name: "AssessmentNew",

	components: {
		"app-breath-sounds": BreathSounds
		//'app-noise-rub': NoiseRub
	},

	data() {
		return {
			call_id: 0,

			disableFacility: false,

			previewHtml: "",

			showIframe: true,

			callPurposes: [], // purpose options form init api call

			form: {
				therapist: {
					name: "",
					id: "",
					credentials: ""
				},

				meta: {
					date: moment().format("MM/DD/YYYY"),
					start: moment().format("h:mm A"),
					end: moment().format("h:mm A"),
					duration: 0,
					facility: "",
					hospitalFax: false
				},

				purpose: [],

				patient: {
					name: {
						first: "",
						last: ""
					},
					identifier: "",
					birthday: {
						mm: "",
						dd: "",
						yy: ""
					},
					gender: ""
				},

				subjective: {
					hospitalAdmissionDx: "",
					providerObtainedInfo: ""
				},

				respiratoryHistory: {
					respiratoryDiagnosis: "",
					currentRespiratoryTherapyTreatments: "",
					respiratoryMedications: "",
					otherMedications: ""
				},

				respiratorySymptoms: {
					symptoms: [],
					otherSymptoms: {
						active: false,
						value: ""
					},
					spO2: "",
					respiratoryRate: "",
					heartRate: "",
					bloodPressure: "",
					temperature: "",
					otherData: ""
				},

				labData: {
					//ABGs: '',
					pH: "",
					CO2: "",
					PO2: "",
					O2: "",
					Sat: "",
					HCO3: "",
					BE: "",
					EtCO2: "",
					//CBC: '',
					WBC: "",
					Hgb: "",
					Na: "",
					K: "",
					otherLabData: ""
					//labTestDescription: ''
				},

				breathSounds: [
					{
						title: "Bilateral",
						slug: "bilateral",
						active: true,
						diminished: "",
						rhonchi: "",
						largeAirway: "",
						crackles: "",
						wheeze: "",
						extraSounds: [],
						otherBreathSound: "",
						used: false
					},
					{
						title: "Right Upper",
						slug: "right_upper",
						active: false,
						diminished: "",
						rhonchi: "",
						largeAirway: "",
						crackles: "",
						wheeze: "",
						extraSounds: [],
						otherBreathSound: "",
						used: false
					},
					{
						title: "Right Middle",
						slug: "right_middle",
						active: false,
						diminished: "",
						rhonchi: "",
						largeAirway: "",
						crackles: "",
						wheeze: "",
						extraSounds: [],
						otherBreathSound: "",
						used: false
					},
					{
						title: "Right Lower",
						slug: "right_lower",
						active: false,
						diminished: "",
						rhonchi: "",
						largeAirway: "",
						crackles: "",
						wheeze: "",
						extraSounds: [],
						otherBreathSound: "",
						used: false
					},
					{
						title: "Left Upper",
						slug: "left_upper",
						active: false,
						diminished: "",
						rhonchi: "",
						largeAirway: "",
						crackles: "",
						wheeze: "",
						extraSounds: [],
						otherBreathSound: "",
						used: false
					},
					{
						title: "Left Lower",
						slug: "left_lower",
						active: false,
						diminished: "",
						rhonchi: "",
						largeAirway: "",
						crackles: "",
						wheeze: "",
						extraSounds: [],
						otherBreathSound: "",
						used: false
					}
				],

				cough: {
					strength: "",
					frequency: "",
					sputumAmount: "",
					sputumQuality: [],
					notes: ""
				},

				assessmentPlan: {
					selections: [],
					plan: ""
				},

				troubleshooting: {
					respiratoryEquipment: "",
					patientSituation: ""
				},

				education: {
					staff: "",
					patient: ""
				}
			},

			//hospital: '',

			facilities: [1, 2, 3],

			requireConfirmationToLeave: false,

			monthsOptions: [],

			formSubmissionSuccessful: false
		};
	},

	watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {
			//watch for route to chage in case user id param has changed in the uri
			this.initForm();
		}
	}, // watch

	computed: {
		...mapGetters(["loggedIn"]),

		iframeSource() {
			return (
				"/api/patient_assessment/preview/true?data=" +
				encodeURIComponent(JSON.stringify(this.form))
			);
		}
	},

	methods: {
		...mapActions([
			"ajax",
			"globalAlert"
			//'setDestination'
		]),

		updateDuration: function() {
			this.$set(this.form.meta, "duration", this.form.meta.duration + 15);
		},

		updateEndTime() {
			console.log("updateEndTime");

			this.$set(this.form.meta, "end", moment().format("h:mm A"));
		},

		submitForm(preview) {
			console.log("submitForm");

			var tis = this;
			tis.showIframe = false;

			this.ajax({
				url: "/patient_assessment/submit" + (preview ? "/preview" : ""),
				//url: '/patient_assessment/fax',
				data: this.form,
				success: json => {
					if (json.success) {
						this.formSubmissionSuccessful = true;

						//alert('The form was successfully submitted.');

						this.globalAlert({
							title: "Form Sent",
							body: "The form was successfully submitted."
						});

						// named route
						this.$router.push("/reports");
					}

					tis.previewHtml = json.html;

					let doc = document.getElementById("previewHtml").contentWindow
						.document;
					doc.open();
					doc.write(tis.previewHtml);
					doc.close();

					//alert('submitted');
				}
			});
		},

		facilitySelected() {
			const hos = this.facilities.find(ele => {
				return ele.id === this.form.meta.facility;
			});

			console.log("facilitySelected", hos);

			this.form.meta.hospitalFax = hos ? "to " + hos.fax : "";
		},

		initForm() {
			const tis = this;

			//this.setDestination('/assessment/new');

			if (this.$route.params.pendingCallId !== "init") {
				//alert('dont disable');

				this.disableFacility = true;
			}

			const id =
				this.$route.params.pendingCallId === "init"
					? ""
					: "/" + this.$route.params.pendingCallId;

			this.ajax({
				url: "/patient_assessment/init" + id,
				data: {},
				success: (json, context) => {
					this.call_id = json.call_id;

					tis.callPurposes = json.call_purposes;

					tis.facilities = json.facilities;

					tis.monthsOptions = json.months;

					tis.form.therapist = json.therapist;

					tis.form.meta.facility = json.facility_id ? json.facility_id : "";

					console.log("context", context);
				}
			});
		}
	},

	mounted() {
		this.initForm();

		// update end time every minute

		setInterval(() => {
			this.updateEndTime();

			this.updateDuration();
		}, 15 * 1000);
	},

	beforeDestroy() {
		//alert('you can never leave');
		//return false;
	},

	beforeRouteLeave(to, from, next) {
		if (this.formSubmissionSuccessful || !this.loggedIn) {
			// you are free to leave the page
			next();
		} else {
			const leaving = window.confirm(
				"Do you really want to leave? you have unsaved changes!"
			);

			if (leaving) {
				var id = this.call_id;

				this.ajax({
					url: "/conference/abandon/" + id,
					//data: {},
					success: () => {
						//this.activePendingId = false;

						next();

						//this.$set(this, 'activePendingId', false);
					}
				});
			} else {
				// stay on the page
				next(false);
			}
		}
	}
};
</script>

<style lang="scss">
.nav-tabs-card {
	border-radius: 0 0 0.25rem 0.25rem;
	border-top: none;
}

// #patient_birth_month {
// 	border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     -webkit-appearance: none;
// }
// #patient_birth_day {
// 	border-radius: 0;
//     -webkit-appearance: none;
// }
</style>
