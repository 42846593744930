//BreathSounds.vue

<template>
	<div>
		<h2 class="pt-5"><i class="material-icons">volume_up</i>Breath Sounds</h2>

		<p class="text-muted">Select a lung location.</p>

		<pre>breathSounds: {{ breathSounds }}</pre>

		<ul class="nav nav-tabs nav-fill pt-3">
			<li
				class="nav-item"
				v-for="(b, n) in breathSounds"
				:key="n + '-breath-sounds'"
			>
				<a
					v-on:click.prevent="activeTab(b)"
					class="nav-link"
					:class="{ active: b.active }"
					href
				>
					<i v-if="b.used" class="material-icons">&#xE5CA;</i>
					{{ b.title }}
				</a>
			</li>
		</ul>

		<transition-group name="fade" mode="out-in">
			<section
				v-for="(b, n) in breathSounds"
				:key="'bs---' + n"
				class="card nav-tabs-card"
				v-show="b.active"
			>
				<section class="card-body mt-2 mb-2">
					<h5>{{ b.title }} Breath Sounds</h5>

					<p class="text-muted">
						This form will be faxed to the hospital below.
					</p>

					<section class="row">
						<div class="col-md-6 col-lg">
							<div class="mt-3">
								<label :for="'diminished_' + n">Diminished</label>
								<select
									v-model="b.diminished"
									class="form-control form-control-lg custom-select"
									:id="'diminished_' + n"
								>
									<option value>- Diminished? -</option>
									<option value="Yes">Yes</option>
									<option value="No">No</option>
									<option
										v-if="b.title == 'Bilateral'"
										value="Diminished Throughout"
										>Diminished Throughout</option
									>
								</select>
							</div>

							<div class="mt-3">
								<label :for="'rhonchi_' + n">Rhonchi</label>
								<input
									v-model="b.rhonchi"
									type="text"
									class="form-control form-control-lg"
									:id="'rhonchi_' + n"
									placeholder="Rhonchi"
								/>
							</div>

							<div class="mt-3">
								<label :for="'largeAirway_' + n">Large Airway</label>
								<input
									v-model="b.largeAirway"
									type="text"
									class="form-control form-control-lg"
									:id="'largeAirway_' + n"
									placeholder="Large Airway"
								/>
							</div>
						</div>

						<div class="col-md-6 col-lg pb-4">
							<div class="mt-3">
								<label :for="'crackles_' + n">Crackles</label>
								<select
									v-model="b.crackles"
									class="form-control form-control-lg custom-select"
									:id="'crackles_' + n"
								>
									<option value>- Select Crackles -</option>
									<option>Fine</option>
									<option>Medium</option>
									<option>Coarse</option>
								</select>
							</div>

							<div class="mt-3">
								<label :for="'wheeze_' + n">Wheeze</label>
								<select
									v-model="b.wheeze"
									class="form-control form-control-lg custom-select"
									:id="'wheeze_' + n"
								>
									<option value>- Select Wheeze -</option>
									<option>Inspiratory</option>
									<option>Expiratory</option>
									<option>I/E</option>
									<option
										v-if="b.title == 'Bilateral'"
										value="Wheezes Throughout"
										>Wheezes Throughout</option
									>
								</select>
							</div>

							<div class="mt-3">
								<label :for="'otherBreathSound_' + n">Other Breath Sound</label>
								<input
									v-model="b.otherBreathSound"
									type="text"
									class="form-control form-control-lg"
									:id="'otherBreathSound_' + n"
									placeholder="Other Breath Sound"
								/>
							</div>
						</div>

						<div class="col-12 col-sm-12 col-lg mt-3">
							<label>Extra</label>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="b.extraSounds"
									type="checkbox"
									:id="'noiseRubAirwayNoiseStridor_' + n"
									class="custom-control-input"
									value="Airway Noise - Stridor"
								/>

								<label
									:for="'noiseRubAirwayNoiseStridor_' + n"
									class="custom-control-label"
									>Airway Noise - Stridor</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="b.extraSounds"
									type="checkbox"
									:id="'noiseRubAirwayNoiseGrunting_' + n"
									class="custom-control-input"
									value="Airway Noise - Grunting"
								/>

								<label
									:for="'noiseRubAirwayNoiseGrunting_' + n"
									class="custom-control-label"
									>Airway Noise - Grunting</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="b.extraSounds"
									type="checkbox"
									:id="'noiseRubPleural_' + n"
									class="custom-control-input"
									value="Rub - Pleural"
								/>

								<label
									:for="'noiseRubPleural_' + n"
									class="custom-control-label"
									>Rub - Pleural</label
								>
							</div>

							<div class="custom-control custom-checkbox form-control-lg">
								<input
									v-model="b.extraSounds"
									type="checkbox"
									:id="'noiseRubPericardial_' + n"
									class="custom-control-input"
									value="Rub - Pericardial"
								/>

								<label
									:for="'noiseRubPericardial_' + n"
									class="custom-control-label"
									>Rub - Pericardial</label
								>
							</div>
						</div>
					</section>
					<!-- row -->
				</section>
			</section>
		</transition-group>
	</div>
</template>

<script>
//import { mapGetters } from 'vuex';

export default {
	name: "BreathSounds",

	props: ["breathSounds"],

	methods: {
		activeTab(b) {
			this.breathSounds.forEach(item => {
				this.$set(item, "active", false);
			});

			this.$set(b, "active", true);
		}
	},

	watch: {
		breathSounds: {
			handler: function(newValue) {
				console.log(newValue);

				this.breathSounds.forEach(item => {
					var used =
						item.crackles != "" ||
						item.diminished != "" ||
						item.largeAirway != "" ||
						item.otherBreathSound != "" ||
						item.rhonchi != "" ||
						item.wheeze != "" ||
						item.extraSounds.length
							? true
							: false;

					this.$set(item, "used", used);
				});

				//console.log("Person with ID:" + newValue.id + " modified")
				//console.log("New age: " + newValue.age)
			},
			deep: true
		}

		/*person: {
            handler: function(newValue) {
                console.log("Person with ID:" + newValue.id + " modified")
                console.log("New age: " + newValue.age)
            },
            deep: true
        }*/
	},

	computed: {
		/* ...mapGetters([
        'loggedIn'
      ])*/
	}
};
</script>

<style></style>
